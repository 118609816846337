<template>
<div class="h-screen flex w-full bg-img relative">
    <div class="vx-col flex items-center justify-center flex-col sm:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/login-main-logo.png" class="mx-auto max-w-full custom-logo-size">
        <h3 class="mt-6 sm:mx-0 mx-4 text-2xl text-white">Please Wait</h3>
    </div>
    <div class="flex items-center justify-between absolute bottom-5 w-full px-5 sm:flex-row flex-col text-center gap-y-2">
        <vs-terms></vs-terms>
        <h6 class="text-lg text-white">Version: {{ currentVersion }}</h6>
    </div>
</div>
</template>

<script>
import {
    version
} from '../../../package.json';

export default {
    data() {
        return {
            currentVersion: version,
        }
    },
    created() {
        console.log("completing sign up");
        this.$router.push("/relogin");
    },
    methods: {}
}
</script>

<style scoped>
.custom-logo-size {
    width: 24rem;
}

@media (max-width: 1024px){
    .custom-logo-size {
        width: 22rem;
    }
}

@media (max-width: 575px){
    .custom-logo-size {
        width: 20rem;
    }
}

@media (max-width: 400px){
    .custom-logo-size {
        width: 17rem;
    }
}
</style>
